<template>
  <v-card flat>
    <v-card-title v-if="user.person.full_name">
      {{ user.person.full_name }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col v-if="user.email" cols="12">
          <p>
            <span class="subtitle-2">
              {{ $t('email') }}
            </span>
            <br>
            <a :href="`mailto:${user.email}`">
              {{ user.email }}
            </a>
          </p>
        </v-col>

        <v-col v-if="user.person.phone" cols="12">
          <p>
            <span class="subtitle-2">
              {{ $t('phone') }}
            </span>
            <br>
            <a :href="`tel:${user.person.phone}`">
              {{ user.person.phone }}
            </a>
          </p>
        </v-col>

        <v-col v-if="user.person.address" cols="12">
          <p>
            <span class="subtitle-2">
              {{ $t('address') }}
            </span>
            <br>
            <a :href="`https://maps.google.com/?q=${user.person.address}`" target="_blank">
              {{ user.person.address }}
            </a>
          </p>
        </v-col>

        <v-col cols="12">
          <p>
            <span class="subtitle-2">
              {{ $t('role') }}
            </span>
            <br>
            <span>
              {{ $t(`roles.${user.role}`) }}
            </span>
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <p>
            <span class="subtitle-2">
              {{ $t('status') }}
            </span>
            <br>
            <span>
              {{ $t(`statuses.${user.status}`) }}
            </span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserInfo',

  props: {
    user: Object,
  },
};
</script>
