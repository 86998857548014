/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { getRequiredFieldLabel } from '@/util/forms';

const formMixin = {
  methods: {
    formMixin_clearErrors(field) {
      if (this.errors[field]) {
        Vue.delete(this.errors, field);
      }
    },

    formMixin_collectMultiSelectErrors(field) {
      let errors = [];
      Object.keys(this.errors).forEach(key => {
        if (key.indexOf(field) > -1) {
          errors = errors[field] ? [...errors[field], ...this.errors[key]] : this.errors[key];
        }
      });
      if (errors.length) {
        this.$set(this.errors, field, errors);
      }
    },

    // Used when BaseAutocomplete.vue component emits a value
    formMixin_setAutocompleteValue(res, formItem, objectField, idField) {
      if (!formItem) {
        return;
      }
      const id = idField || `${objectField}_id`;
      if (res) {
        this.$set(formItem, objectField, res);
        this.$set(formItem, id, res.id);
      } else {
        this.$set(formItem, objectField, null);
        this.$set(formItem, id, null);
      }
    },

    formMixin_getRequiredFieldLabel(label) {
      return getRequiredFieldLabel(label);
    },
  },
};

export default formMixin;
