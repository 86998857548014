<template>
  <div v-if="currentUser" class="full-width">
    <UserInfo
      :user="currentUser"
    />
    <div>
      <v-btn
        color="primary"
        class="ml-3"
        @click="isPasswordChangeFormOpen = true"
      >
        {{ $t('change_password') }}
      </v-btn>
    </div>

    <v-dialog
      v-model="isPasswordChangeFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      scrollable
    >
      <PasswordChangeForm
        @close="isPasswordChangeFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import PasswordChangeForm from '../components/PasswordChangeForm';
import UserInfo from '../components/UserInfo';

export default {
  name: 'Profile',

  components: {
    UserInfo,
    PasswordChangeForm,
  },

  data() {
    return {
      isPasswordChangeFormOpen: false,
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>
