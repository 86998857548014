<template>
  <v-form ref="form" @submit.prevent="changePassword">
    <v-card>
      <v-card-title>
        {{ $t('password_change') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="passwords.current_password"
              :error-messages="errors.current_password"
              :append-icon="isCurrentPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isCurrentPasswordHidden ? 'password' : 'text'"
              :label="$t('current_password')"
              name="current_password"
              autofocus
              @click:append="isCurrentPasswordHidden = !isCurrentPasswordHidden"
              @blur="formMixin_clearErrors('current_password')"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="passwords.new_password"
              :error-messages="errors.new_password"
              :append-icon="isNewPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isNewPasswordHidden ? 'password' : 'text'"
              :label="$t('new_password')"
              name="new_password"
              @click:append="isNewPasswordHidden = !isNewPasswordHidden"
              @blur="formMixin_clearErrors('new_password')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          :disabled="isRequestPending"
          color="primary"
          type="submit"
          text
          @click.native="changePassword"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '../mixins/form-mixin';
import userService from '../api/user-service';
import { mapErrorsToInputs } from '../util/forms';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';
import dialogMixin from '../mixins/dialog-mixin';

export default {
  name: 'PasswordChangeForm',

  mixins: [formMixin, dialogMixin],

  data() {
    return {
      isNewPasswordHidden: true,
      isCurrentPasswordHidden: true,
      isRequestPending: false,
      passwords: {
        current_password: '',
        new_password: '',
      },
      errors: {},
    };
  },

  methods: {
    onDialogOpen() {
      this.errors = {};
      this.passwords = {
        current_password: '',
        new_password: '',
      };
    },

    async changePassword() {
      this.isRequestPending = true;

      try {
        await userService.changeCurrentUserPassword(this.passwords);
        eventBus.$emit(OPEN_SNACKBAR, this.$t('password_was_changed'));
        this.$emit('close');
      } catch (e) {
        this.errors = mapErrorsToInputs(e);
      }

      this.isRequestPending = false;
    },
  },
};
</script>
